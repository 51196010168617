<script>
import axios from "axios";
import Swal from "sweetalert2";
// import Swal from "sweetalert2";
import Multiselect from "@vueform/multiselect";
export default {
  props: ["simulasi_id_store", "simulasiconfig_id", "filter_simulasi_file", "jumlah_petunjuk"],
  components: {
    Multiselect,
  },
  data() {
    return {
      // variable Page
      option_tipe_file: ["PDF", "JPEG", "Rich Text", "PPT", "Excel", "Video"],
      option_tipe_file_materi: ["MEMO", "MAIL", "SMS", "WA", "VIDEO", "NEWS", "AUDIO"],
      url_backend_file: process.env.VUE_APP_BACKEND_URL,
      url_backend_pdf_js: process.env.VUE_APP_BACKEND_URL + "/pdf.js/web/viewer.html?file=",
      table_data: [],
      selected_simulasi_file: [],
      loadingTable: false,
      option_simulasi_config: [],
      option_materi: [],
      selected_simulasi: [],
      selected_materi: [],
      id_materi_selected: "",
      id_simulasi_config_selected: "",
    };
  },
  mounted() {
    // this.getDataTable();
    this.getMateri();
  },
  methods: {
    getMateri(keyword) {
      let self = this;

      var config_get_jenis_asesmen = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/materi",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          keyword,
        },
      };

      axios(config_get_jenis_asesmen)
        .then(function (response) {
          self.option_materi = response.data.data.referensi;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
        });
    },
    getSimulasiConfig(keyword) {
      let self = this;

      var config_get_jenis_asesmen = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/simulasi-by-materi",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          keyword,
          id_materi: this.id_materi_selected,
        },
      };

      axios(config_get_jenis_asesmen)
        .then(function (response) {
          self.option_simulasi_config = response.data.data.referensi;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
        });
    },
    searchMateri(data) {
      this.getMateri(data);
    },
    searchSimulasi(data) {
      this.getSimulasiConfig(data);
    },
    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi-files/get-by-simulasi") {
      let self = this;
      self.loadingTable = true;

      var config = {
        method: "get",
        url: url,
        params: {
          simulasiconfig_id: this.id_simulasi_config_selected,
          isPetunjuk: 0,
          filter_simulasi_file: this.filter_simulasi_file,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            this.loadingTable = false;
            self.table_data = response_data_fix;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/materi-files",
        data: {
          simulasi_file_id: this.selected_simulasi_file,
          simulasi_id: this.simulasi_id_store,
          isPetunjuk: false,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master simulasi  segera",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$emit("item-added");
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
    onFileChange(e) {
      this.form_data.file = e.target.files[0];
    },
    onSelectSimulasiFile(data) {
      if (this.jumlah_petunjuk == "Single" && this.selected_simulasi_file.length > 0) {
        alert("Maksimal 1 File karena tipe soal single");
      } else {
        if (this.checkIfConfigExists(data.simulasi_file_id)) {
          alert("exist");
        } else {
          this.selected_simulasi_file.push(data);
        }
      }
    },
    checkIfConfigExists(id) {
      return this.selected_simulasi_file.some((item) => item.simulasi_file_id === id);
    },
    removeConfig(id) {
      this.selected_simulasi_file = this.selected_simulasi_file.filter((item) => item.simulasi_file_id !== id);
      console.log(this.selected_simulasi_file);
    },
    onSelectMateri() {
      this.id_materi_selected = this.selected_materi.mm_id;
      this.getSimulasiConfig();
    },
    onSelectSimulasi() {
      this.id_simulasi_config_selected = this.selected_simulasi.id_simulasi_config;
      this.getDataTable();
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-sm-8">
      <label>Materi</label>
      <multiselect
        v-model="selected_materi"
        :options="option_materi"
        :object="true"
        value-prop="mm_id"
        :close-on-select="true"
        track-by="mm_nama"
        :searchable="true"
        label="mm_nama"
        placeholder="Pilih Materi"
        @search-change="searchMateri($event)"
        @select="onSelectMateri"
      ></multiselect>
    </div>
  </div>
  <div class="row mt-2" v-if="id_materi_selected">
    <div class="col-sm-8">
      <label>Simulasi</label>
      <multiselect
        v-model="selected_simulasi"
        :options="option_simulasi_config"
        :object="true"
        value-prop="id_simulasi_config"
        :close-on-select="true"
        track-by="nama_simulasi"
        :searchable="true"
        label="nama_simulasi"
        placeholder="Pilih Simulasi"
        @search-change="searchSimulasi($event)"
        @select="onSelectSimulasi"
      ></multiselect>
    </div>
  </div>
  <b-form class="p-2 mt-4" @submit.prevent="StoreData">
    <div class="row">
      <h6>Pilih File</h6>
    </div>
    <div class="row mt-2">
      <table class="table mb-0 table-bordered table-condensed table-hover">
        <thead class="bg-dark text-center text-white">
          <tr>
            <th>No</th>
            <th>Judul File</th>
            <th>Deskripsi</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!id_materi_selected">
            <td colspan="6">Silahkan Pilih Materi dan Simulasi</td>
          </tr>
          <tr v-else-if="loadingTable">
            <td colspan="6"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
          </tr>
          <tr v-else-if="table_data == ''">
            <td class="text-center" colspan="6">Data Tidak Tersedia</td>
          </tr>
          <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
            <td class="text-center">
              {{ key_data + 1 }}
            </td>
            <td>{{ row_data.judul }}</td>
            <td>{{ row_data.deskripsi }}</td>
            <td class="text-center">
              <a :href="url_backend_pdf_js + url_backend_file + row_data.file" target="_blank" class="btn btn-primary btn-sm"><i class="fa fa-eye me-2"></i>Lihat File</a>
              <button class="btn btn-success btn-sm ms-2" type="button" v-if="!checkIfConfigExists(row_data.simulasi_file_id)" @click="onSelectSimulasiFile(row_data)">
                <i class="fa fa-plus me-2"></i>Pilih
              </button>
              <button class="btn btn-danger btn-sm ms-2" type="button" v-else @click="removeConfig(row_data.simulasi_file_id)"><i class="fa fa-trash me-2"></i>Hapus</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-2" v-for="items in selected_simulasi_file" :key="items">
      <div class="col-md-6">
        <b-form-group class="mb-3" label="Judul" label-for="formrow-nama-role-input">
          <b-form-input id="formrow-nama-role-input" placeholder="Judul" type="text" v-model="items.judul" :readonly="true"></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group class="mb-3" label="Nama File" label-for="formrow-nama-role-input">
          <b-form-input id="formrow-nama-role-input" placeholder="Nama File" type="text" v-model="items.nama_file" :readonly="true"></b-form-input>
        </b-form-group>
      </div>
    </div>

    <hr />
    <div class="row mt-2">
      <div class="col-12 text-end">
        <button type="submit" class="btn btn-primary btn-md"><i class="fa fa-edit"></i> Simpan</button>
      </div>
    </div>
  </b-form>
</template>
