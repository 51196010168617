<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import { containsDisallowedCharacters } from "../../../../utils/check-disallowed-character";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Simulasi Config",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "Tambah Simulasi Config",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Simulasi Config",
          href: "/master/simulasi-config",
        },
        {
          text: "Tambah Simulasi Config",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      option_jumlah_petunjuk: ["Single", "Multiple"],
      option_jumlah_soal: ["Single", "Multiple"],
      option_tipe_jawaban: ["Rich Text", "PPT (Only Office)", "Rich Text - Interaction", "PPT (Only Office) - Interaction", "Rich Text - Discussion"],
      option_jumlah_jawaban: ["Single", "1 Soal 1 Jawaban"],
      option_tipe_penjadwalan: ["1 Simulasi 1 Batch", "1 Simulasi per orang beda batch"],
      option_tipe_code_analytic: ["jawaban", "upload file"],
      option_simulasi_config: [],
      status: "not_accepted",
      // variable Page
      form_data: {
        nama_simulasi: "",
        nama_sub_simulasi: "",
        durasi: "",
        jumlah_petunjuk: "",
        jumlah_soal: "",
        tipe_jawaban: "",
        jumlah_jawaban: "",
        icon: "",
        deskripsi: "",
        status: "",
        acakan_simulasi: "",
        tipe_penjadwalan: "",
        code_tipe_analytics: "",
      },
    };
  },
  mounted() {
    this.getSimulasiConfig();
  },
  methods: {
    getSimulasiConfig(keyword) {
      let self = this;

      var config_get_jenis_asesmen = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/simulasi-config",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          keyword,
        },
      };

      axios(config_get_jenis_asesmen)
        .then(function (response) {
          self.option_simulasi_config = response.data.data.referensi;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
        });
    },
    // Method dari masing masing fungsi di dalam file ini
    StoreData() {
      let self = this;
      if (containsDisallowedCharacters(self.form_data.deskripsi)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terdapat karakter yang tidak diperbolehkan!",
        });
        return; // Exit the function if validation fails
      }
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi-config",
        data: this.form_data,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master simulasi config segera",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "all-simulasi-config" });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
    async uploadFile(event) {
      var file = event.target.files[0];
      let self = this;

      if (file) {
        Swal.fire({
          title: '<i class="fas fa-spinner fa-spin"></i>',
          text: "Loading...",
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        if (file.size < 2242880) {
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", file);
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            console.log(response.data.data.path_file);
            var path_file = response.data.data.path_file;

            self.form_data.icon = path_file;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "File berhasil ditambahkan",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
              }
            });
          });
        } else {
          event.target.value = null;
          Swal.fire({
            icon: "error",
            title: "oops",
            text: "Maksimal File 2 MB",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
            }
          });
        }
      }
    },
    searchSimulasiConfig(data) {
      this.getSimulasiConfig(data);
    },
    // onChangeTipePenjadwalan(data) {
    //   if (data == "1 Simulasi per orang beda batch") {
    //     this.form_data.durasi = 0;
    //   }
    // },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    <div v-for="(allErrors, idx) in errorArray" :key="idx">
                      <span class="text-danger">{{ allErrors[0] }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-form-group class="mb-3" label="Nama Simulasi" label-for="formrow-nama-role-input">
                    <b-form-input id="formrow-nama-role-input" placeholder="Masukkan Nama Simulasi..." type="text" v-model="form_data.nama_simulasi" required></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-form-group class="mb-3" label="Kode Simulasi" label-for="formrow-nama-role-input">
                    <b-form-input id="formrow-nama-role-input" placeholder="Masukkan Kode Simulasi..." type="text" v-model="form_data.nama_sub_simulasi"></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <label>Jumlah Petunjuk</label>
                  <multiselect v-model="form_data.jumlah_petunjuk" :options="option_jumlah_petunjuk" placeholder="Pilih Jumlah Petunjuk" required></multiselect>
                </div>
                <div class="col-sm-3">
                  <label>Jumlah Soal</label>
                  <multiselect v-model="form_data.jumlah_soal" :options="option_jumlah_soal" placeholder="Pilih Jumlah Soal" required></multiselect>
                </div>
                <div class="col-sm-3">
                  <label>Tipe Jawaban</label>
                  <multiselect v-model="form_data.tipe_jawaban" :options="option_tipe_jawaban" placeholder="Pilih Tipe Jawaban" required></multiselect>
                </div>
                <div class="col-sm-3">
                  <label>Jumlah Jawaban</label>
                  <multiselect v-model="form_data.jumlah_jawaban" :options="option_jumlah_jawaban" placeholder="Pilih Jumlah Jawaban" required></multiselect>
                </div>
              </div>
              <div class="row mt-4">
                <!-- 
                  <div class="col-sm-3">
                    <label>Tipe Penjadwalan</label>
                    <multiselect
                      v-model="form_data.tipe_penjadwalan"
                      :options="option_tipe_penjadwalan"
                      @change="onChangeTipePenjadwalan($event)"
                      placeholder="Pilih Jumlah Petunjuk"
                      required
                    ></multiselect>
                  </div> 
                  <div class="col-md-3" v-if="form_data.tipe_penjadwalan == '1 Simulasi 1 Batch'">
                    <b-form-group class="mb-3" label="Durasi" label-for="formrow-nama-role-input">
                      <b-form-input id="formrow-nama-role-input" placeholder="Masukkan Durasi..." type="number" v-model="form_data.durasi" required></b-form-input>
                    </b-form-group>
                  </div> 
                -->
                <div class="col-sm-3">
                  <label>Tipe Penjadwalan</label>
                  <multiselect v-model="form_data.tipe_penjadwalan" :options="option_tipe_penjadwalan" placeholder="Pilih Jumlah Petunjuk" required></multiselect>
                </div>
                <div class="col-md-3">
                  <b-form-group class="mb-3" label="Durasi" label-for="formrow-nama-role-input">
                    <b-form-input id="formrow-nama-role-input" placeholder="Masukkan Durasi..." type="number" v-model="form_data.durasi" required></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <!-- <div class="row mt-4">
                <div class="col-sm-12">
                  <div class="form-check-primary mb-3">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="status"
                      name="checkbox-1"
                      value="accepted"
                      unchecked-value="not_accepted"
                    >
                      Acakan mengikuti simulasi lain
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
              <div class="row" v-if="status == 'accepted'">
                <div class="col-sm-8">
                  <label>Pilih Simulasi Config Lain</label>
                  <multiselect
                    v-model="form_data.acakan_simulasi"
                    :options="option_simulasi_config"
                    :object="true"
                    value-prop="simulasiconfig_id"
                    :close-on-select="true"
                    track-by="nama"
                    :searchable="true"
                    label="nama"
                    placeholder="Pilih Simulasi Lain"
                    @search-change="searchSimulasiConfig($event)"
                  ></multiselect>
                </div>
              </div> -->
              <div class="row">
                <div class="col-sm-12">
                  <div class="mt-3">
                    <label for="formFile" class="form-label">Icon</label>
                    <input class="form-control" type="file" id="formFile" ref="inputFile" v-on:change="uploadFile" required />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="mt-3">
                    <label for="formFile" class="form-label">Deskripsi</label>
                    <textarea v-model="form_data.deskripsi" class="form-control" name="textarea" rows="5" required></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="mt-3">
                    <label>Opsi Tipe Kode Analytic</label>
                    <multiselect v-model="form_data.code_tipe_analytics" :options="option_tipe_code_analytic" placeholder="Pilih Tipe Kode Analytic" required></multiselect>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="mt-3">
                    <label>Status</label>
                    <multiselect v-model="form_data.status" :options="['ENABLE', 'DISABLE']" placeholder="Pilih Status" required></multiselect>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">
                  <div class="text-start">
                    <router-link :to="{ name: 'all-simulasi-config' }" class="btn btn-warning"><i class="fa fa-arrow-left"></i> Kembali</router-link>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="text-end">
                    <!-- <b-button type="reset" variant="danger"
                      ><i class="fa fa-redo-alt"></i> Reset</b-button
                    > -->
                    &nbsp;
                    <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
