<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  page: {
    title: "Kuesioner",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "id_role" },
      { width: "auto", label: "Nama Simulasi", name: "role_name" },
      { width: "auto", label: "Sub Nama", name: "role_name" },
      // { width: "auto", label: "Deskripsi", name: "role_description" },
      // { width: "150px", label: "Created At", name: "created_at" },
      // { width: "150px", label: "Updated At", name: "updated_at" },
      // { width: "100px", label: "Status", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
      { width: "175px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Kuesioner Combo",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Kuesioner Combo",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",

      // parsing data ke table data
      tableData: {
        per_page: 10,
        search: "",
        sort_field: 0,
        sort_order: "desc",
        filter_status: "ENABLE", // untuk filtering data
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    let self = this;
    self.getDataTable();
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi-config") {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list;
            console.log(self.table_data);
            self.configPagination(response_data_fix.pagination);
            self.currentTablePage = response_data_fix.pagination.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <div class="row">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.per_page" @change="getDataTable()">
                            <option value="10" selected>10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..." v-model="tableData.search" @input="getDataTable()" />
                  </div>
                </div>
              </div>
              <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                <tbody>
                  <tr v-if="loadingTable">
                    <td colspan="6"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                  </tr>
                  <tr v-else-if="table_data == ''">
                    <td class="text-center" colspan="6">Data Tidak Tersedia</td>
                  </tr>
                  <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                    <td class="text-center">
                      <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                      <div v-else>{{ currentTablePage - 1 != 0 ? currentTablePage - 1 : "" }}{{ key_data + 1 }}.</div>
                    </td>
                    <td>
                      {{ row_data.nama }}
                    </td>
                    <td>
                      {{ row_data.sub_nama }}
                    </td>
                    <td class="text-center">
                      <div class="btn-group">
                        <router-link
                          :to="{
                            name: 'detail-kuisioner-combo',
                            params: { id: row_data.simulasiconfig_id },
                            data: { simulasiName: row_data.nama },
                          }"
                          class="btn btn-info btn-sm"
                        >
                          <i class="fa fa-eye"></i> Detail
                        </router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </datatable>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
