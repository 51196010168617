<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import { containsDisallowedCharacters } from "../../../../utils/check-disallowed-character";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Edit Materi Materi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "Edit Materi Materi",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Simulasi Config",
          href: "/master/simulasi-config",
        },
        {
          text: "Edit Materi Simulasi Config",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      option_jenis_asesmen: [],
      // variable Page
      detail_materi: [],
      form_data: {
        judul_materi: "",
        jenis_asesmen: "",
        deskripsi: "",
        status_materi: "",
      },
    };
  },
  mounted() {
    this.getJenisAsesmen();
    this.getData();
  },
  methods: {
    getData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/materi/" + this.$route.params.id,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then(function (response) {
          Swal.close();
          var response_data = response.data.data;
          self.form_data.judul_materi = response_data.mm_nama;
          self.form_data.deskripsi = response_data.description;
          self.form_data.status_materi = response_data.status_materi;
          console.log(response_data.jenis_asesmen);
          if (response_data.jenis_asesmen.status != "Aktif") {
            console.log("as");
          } else {
            self.form_data.jenis_asesmen = self.option_jenis_asesmen.find((option) => option.id == response_data.mm_id_jenisasesmen);
          }
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
    getJenisAsesmen(keyword) {
      let self = this;

      var config_get_jenis_asesmen = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/jenis-asesmen",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          keyword,
        },
      };

      axios(config_get_jenis_asesmen)
        .then(function (response) {
          self.option_jenis_asesmen = response.data.data.referensi;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
        });
    },
    // Method dari masing masing fungsi di dalam file ini
    StoreData() {
      let self = this;
      if (containsDisallowedCharacters(self.form_data.deskripsi)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terdapat karakter yang tidak diperbolehkan!",
        });
        return; // Exit the function if validation fails
      }
      if (self.form_data.jenis_asesmen) {
        Swal.fire({
          title: '<i class="fas fa-spinner fa-spin"></i>',
          text: "Loading...",
          showConfirmButton: false,
        });

        var config_store_data = {
          method: "put",
          url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/materi/" + this.$route.params.id,
          data: this.form_data,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        };

        axios(config_store_data)
          .then(function (response) {
            console.log(response);
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman master materi config segera",
              timer: 1000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                self.$router.push({ name: "all-materi" });
              }
            });
          })
          .catch(function (error) {
            self.axiosCatchError = error.response.data.data;
            Swal.close();
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Ada data yang perlu diisi",
          timer: 1000,
          timerProgressBar: true,
          showCancelButton: false,
          showConfirmButton: false,
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            Swal.close();
          }
        });
      }
    },
    async uploadFile(event) {
      var file = event.target.files[0];
      let self = this;

      if (file) {
        Swal.fire({
          title: '<i class="fas fa-spinner fa-spin"></i>',
          text: "Loading...",
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        if (file.size < 2242880) {
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", file);
          var config = {
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            console.log(response.data.data.path_file);
            var path_file = response.data.data.path_file;

            self.form_data.icon = path_file;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "File berhasil ditambahkan",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
              }
            });
          });
        } else {
          event.target.value = null;
          Swal.fire({
            icon: "error",
            title: "oops",
            text: "Maksimal File 2 MB",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
            }
          });
        }
      }
    },
    searchData(data) {
      this.getJenisAsesmen(data);
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    <div v-for="(allErrors, idx) in errorArray" :key="idx">
                      <span class="text-danger">{{ allErrors[0] }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-form-group class="mb-3" label="Judul Materi" label-for="formrow-nama-role-input">
                    <b-form-input id="formrow-nama-role-input" placeholder="Masukkan Judul Materi..." type="text" v-model="form_data.judul_materi" required></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <label>Jenis Asesmen</label>
                  <multiselect
                    v-model="form_data.jenis_asesmen"
                    :options="option_jenis_asesmen"
                    :object="true"
                    value-prop="id"
                    :close-on-select="true"
                    track-by="nama_jenisasesmen"
                    :searchable="true"
                    label="nama_jenisasesmen"
                    placeholder="Pilih Jenis Asesmen"
                    @search-change="searchData($event)"
                  ></multiselect>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12">
                  <label>Deskripsi</label>
                  <textarea v-model="form_data.deskripsi" class="form-control" name="textarea" rows="5"></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="mt-3">
                    <label>Kategori</label>
                    <multiselect v-model="form_data.status_materi" :options="['Ujicoba', 'Materi']" placeholder="Pilih Kategori"></multiselect>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">
                  <div class="text-start">
                    <router-link :to="{ name: 'all-materi' }" class="btn btn-warning"><i class="fa fa-arrow-left"></i> Kembali</router-link>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="text-end">
                    <b-button type="reset" variant="danger"><i class="fa fa-redo-alt"></i> Reset</b-button>
                    &nbsp;
                    <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
