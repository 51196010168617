<script>
export default {
  data() {
    return {
      configuration: JSON.parse(localStorage.getItem("configuration")),
    };
  },
};
</script>
<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          {{ new Date().getFullYear() }} ©
          {{ configuration.COPYRIGHT_COPORATION }}.
        </div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">
            Design & Develop by {{ configuration.COPYRIGHT_COPORATION }}. Ver.
            {{ configuration.VERSION }}.
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>
