<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import DetailSettingMateri from "./detail-setting-materi.vue";
import ModalAddSimulasi from "./modal-add-simulasi.vue";
import ModalCopyMateri from "./modal-copy-materi.vue";
import axios from "axios";
import Swal from "sweetalert2";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Materi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
    DetailSettingMateri,
    ModalAddSimulasi,
    ModalCopyMateri,
  },
  data() {
    return {
      table_data: [],
      tableData: {
        per_page: 10,
        search: "",
        sort_field: 0,
        sort_order: "desc",
        materi_id: this.$route.params.id,
      },
      selected_simulasi: "",
      selected_simulasi_config: "",
      selected_jumlah_soal: "",
      selected_jumlah_petunjuk: "",
      renderDetail: false,
      form_simulasi: false,
      existing_simulasi_config_id: [],
      existing_no_urut: [],
      detail_materi: [],
      is_locked_materi: "",

      showModalCopyMateri: false,
    };
  },
  mounted() {
    this.getDataTable();
    this.getData();
  },
  methods: {
    refreshTable() {
      let self = this;
      self.getDataTable();
    },
    // Method dari masing masing fungsi di dalam file ini
    getData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/materi/" + this.$route.params.id,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then(function (response) {
          Swal.close();
          var response_data = response.data.data;
          self.detail_materi = response_data;
          self.is_locked_materi = self.detail_materi.is_locked;
          console.log(self.detail_materi);
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi") {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix;
            self.table_data.forEach((element) => {
              self.existing_simulasi_config_id.push(element.id_simulasi_config);
              self.existing_no_urut.push(element.urutan);
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async showDetail(id, id_simulasi_config, jumlah_soal, jumlah_petunjuk) {
      this.renderDetail = false;
      await this.$nextTick();
      this.selected_simulasi = id;
      this.selected_simulasi_config = id_simulasi_config;
      this.selected_jumlah_soal = jumlah_soal;
      this.selected_jumlah_petunjuk = jumlah_petunjuk;
      this.renderDetail = true;
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi/" + id,
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  window.location.reload();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    lockMateri(status) {
      let self = this;
      let is_locked;
      let text_alert = "";
      if (status == "LOCK") {
        text_alert = "Apakah anda yakin ingin mengunci materi ini ?";
        is_locked = true;
      } else {
        text_alert = "Apakah anda yakin ingin membuka kunci materi ini?";
        is_locked = false;
      }

      Swal.fire({
        title: "Perhatian",
        text: text_alert,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "put",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/materi/lock-materi/" + this.$route.params.id + "/" + is_locked,
            data: {},
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil diperbarui.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  window.location.reload();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },

    onClickModalCopyMateri() {
      this.showModalCopyMateri = !this.showModalCopyMateri;
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-8">
            <h3>Setting Materi : {{ detail_materi?.mm_nama }}</h3>
            <b-alert show variant="danger" v-if="!detail_materi.is_locked">Materi Belum Dikunci</b-alert>
          </div>

          <div class="col-lg-4 text-end" v-if="detail_materi?.is_locked == 1">
            <button
              type="button"
              class="btn btn-warning btn-sm"
              @click="lockMateri('UNLOCK')"
            >
              <i class="fa fa-unlock"></i> Buka Pengaturan Materi
            </button>
          </div>
          <div class="col-lg-4 text-end" v-else-if="detail_materi?.is_locked == 0">
            <button
              type="button"
              class="btn btn-success btn-sm me-2"
              @click="onClickModalCopyMateri"
            >
              <i class="fa fa-copy"></i> Copy Materi
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="lockMateri('LOCK')"
            >
              <i class="fa fa-lock"></i> Kunci Pengaturan Materi
            </button>
          </div>
        </div>
        <div class="card mt-4">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-5">
                <div class="row">
                  <div class="col-6">
                    <h5>Setting Simulasi</h5>
                  </div>
                  <div class="col-6 text-end">
                    <button type="button" class="btn btn-info btn-sm" @click="form_simulasi = !form_simulasi" v-if="!is_locked_materi"><i class="fa fa-plus"></i> Tambah Simulasi</button>
                  </div>
                </div>

                <div class="table-responsive mt-2">
                  <table class="table mb-0 table-bordered table-hover">
                    <thead class="bg-dark text-white">
                      <td class="p-2">No Urut</td>
                      <td class="p-2">Nama Simulasi</td>
                      <td class="p-2">Petunjuk</td>
                      <td class="p-2">Materi</td>
                      <td class="p-2 text-center">Aksi</td>
                    </thead>
                    <tbody>
                      <tr v-for="(row_data, key_data) in table_data" :key="key_data">
                        <td>{{ row_data?.urutan }}</td>
                        <td>{{ row_data.simulasi_config?.nama }}</td>
                        <td>{{ row_data.count_petunjuk ?? "0" }}</td>
                        <td>{{ row_data.count_materi ?? "0" }}</td>
                        <td class="text-center">
                          <div class="btn-group">
                            <button
                              type="button"
                              class="btn btn-info btn-sm"
                              @click="showDetail(row_data.id_simulasi, row_data.simulasi_config?.simulasiconfig_id, row_data.simulasi_config?.jumlah_soal, row_data.simulasi_config?.jumlah_petunjuk)"
                            >
                              <i class="fa fa-eye"></i> Detail
                            </button>
                            <button type="button" class="btn btn-danger btn-sm" v-on:click="deleteData(row_data.id_simulasi)" v-if="!is_locked_materi"><i class="fa fa-trash"></i> Hapus</button>
                            <div v-if="row_data.is_enable">
                              <button type="button" class="btn btn-warning btn-sm" v-on:click="changeStatusData(row_data.is_enable, row_data.id_simulasi)" v-if="!is_locked_materi">
                                <i class="fa fa-minus-circle"></i> DISABLE
                              </button>
                            </div>
                            <div v-else>
                              <button type="button" class="btn btn-success btn-sm" v-on:click="changeStatusData(row_data.is_enable, row_data.id_simulasi)" v-if="!is_locked_materi">
                                <i class="fa fa-eye"></i> ENABLE
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-7" v-if="renderDetail">
                <DetailSettingMateri
                  v-if="renderDetail"
                  :id_simulasi="selected_simulasi"
                  :id_simulasi_config="selected_simulasi_config"
                  :jumlah_soal="selected_jumlah_soal"
                  :jumlah_petunjuk="selected_jumlah_petunjuk"
                  :is_locked_materi="is_locked_materi"
                  @item-added="refreshTable"
                />
              </div>
              <div class="col-7" v-else>
                <b-alert show variant="primary">Silahkan pilih detail simulasi !</b-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-if="form_simulasi" v-model="form_simulasi" id="modal-lg" title="Tambah Simulasi" size="lg" title-class="font-18" hide-footer>
      <ModalAddSimulasi :existing_simulasi_config_id="existing_simulasi_config_id" :existing_no_urut="existing_no_urut" />
    </b-modal>
    <b-modal
      v-if="showModalCopyMateri"
      v-model="showModalCopyMateri"
      id="modal-lg"
      title="Copy Materi"
      size="lg"
      title-class="font-18"
      hide-footer
    >
      <ModalCopyMateri
        v-if="showModalCopyMateri"
        :existing_simulasi_config_id="existing_simulasi_config_id"
        :existing_no_urut="existing_no_urut"
      />
    </b-modal>
  </Layout>
</template>
