// import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login.vue"),
  },
  {
    path: "/acces-denied",
    name: "acces-denied",
    component: () => import("./views/denied-access.vue"),
  },
  {
    path: "/loginportal",
    name: "loginportal",
    component: () => import("./views/account/portal.vue"),
  },
  {
    path: "/",
    name: "dashboard",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/dashboard.vue"),
  },
  {
    path: "/",
    name: "dashboard-admin",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/dashboard-admin.vue"),
  },
  {
    path: "/setting-jadwal/:id",
    name: "setting-jadwal",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/setting-jadwal/index-setting-jadwal.vue"),
  },
  {
    path: "/batch/:idJadwal",
    name: "all-batch",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/batch/index-batch.vue"),
  },
  {
    path: "/list-jadwal/:idJadwal",
    name: "all-list-jadwal",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/jadwal/index-jadwal.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: { authRequired: true },
    component: () => import("./views/account/profile.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: { authRequired: true },
    component: () => import("./views/account/logout.vue"),
  },

  // routes Master

  // routes master role
  {
    path: "/master/role",
    name: "all-role",
    meta: { authRequired: true },
    component: () => import("./views/master/role/all-role.vue"),
  },
  {
    path: "/master/role/tambah",
    name: "add-role",
    meta: { authRequired: true },
    component: () => import("./views/master/role/add-role.vue"),
  },
  {
    path: "/master/role/edit/:id",
    name: "edit-role",
    meta: { authRequired: true },
    component: () => import("./views/master/role/edit-role.vue"),
  },

  // routes master menu
  {
    path: "/master/menu",
    name: "all-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/all-menu.vue"),
  },
  {
    path: "/master/menu/tambah",
    name: "add-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/add-menu.vue"),
  },
  {
    path: "/master/menu/edit/:id",
    name: "edit-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/edit-menu.vue"),
  },
  {
    path: "/master/menu/ordering",
    name: "ordering-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/ordering-menu.vue"),
  },
  {
    path: "/master/menu/role-menu",
    name: "role-menu",
    meta: { authRequired: true },
    component: () => import("./views/master/menu/role-menu.vue"),
  },

  // routes master user
  {
    path: "/master/user",
    name: "all-user",
    meta: { authRequired: true },
    component: () => import("./views/master/user/all-user.vue"),
  },
  {
    path: "/master/user/tambah",
    name: "add-user",
    meta: { authRequired: true },
    component: () => import("./views/master/user/add-user.vue"),
  },
  {
    path: "/master/user/edit/:id",
    name: "edit-user",
    meta: { authRequired: true },
    component: () => import("./views/master/user/edit-user.vue"),
  },

  // routes master konfigurasi
  {
    path: "/master/konfigurasi",
    name: "all-konfigurasi",
    meta: { authRequired: true },
    component: () => import("./views/master/konfigurasi/all-konfigurasi.vue"),
  },

  //routes master pohon profesi
  {
    path: "/master/pohon-profesi",
    name: "all-pohon-profesi",
    meta: { authRequired: true },
    component: () => import("./views/master/pohon-profesi/all-pohon-profesi.vue"),
  },

  //routes master menu page
  {
    path: "/master/page",
    name: "all-page",
    meta: { authRequired: true },
    component: () => import("./views/master/page/all-page.vue"),
  },
  {
    path: "/master/page/tambah",
    name: "add-page",
    meta: { authRequired: true },
    component: () => import("./views/master/page/add-page.vue"),
  },
  {
    path: "/master/page/edit/:id",
    name: "edit-page",
    meta: { authRequired: true },
    component: () => import("./views/master/page/edit-page.vue"),
  },

  //routes master menu detail page
  // {
  //   path: "/master/detail-page/:id",
  //   name: "detail-page",
  //   meta: { authRequired: true },
  //   component: () => import("./views/master/detail-page/all-detail-page.vue"),
  // },

  // (WIP) routes menu dynamic render
  {
    path: "/render",
    name: "render",
    meta: { authRequired: true },
    component: () => import("./views/render.vue"),
  },

  //Simulasi Config
  {
    path: "/master/simulasi-config",
    name: "all-simulasi-config",
    meta: { authRequired: true },
    component: () => import("./views/master/simulasi-config/all-simulasi-config.vue"),
  },
  {
    path: "/master/simulasi-config/tambah-simulasi-config",
    name: "add-simulasi-config",
    meta: { authRequired: true },
    component: () => import("./views/master/simulasi-config/add-simulasi-config.vue"),
  },
  {
    path: "/master/simulasi-config/:id/edit",
    name: "edit-simulasi-config",
    meta: { authRequired: true },
    component: () => import("./views/master/simulasi-config/edit-simulasi-config.vue"),
  },

  //Materi
  {
    path: "/master/materi",
    name: "all-materi",
    meta: { authRequired: true },
    component: () => import("./views/master/materi/all-materi.vue"),
  },
  {
    path: "/master/materi/tambah-materi",
    name: "add-materi",
    meta: { authRequired: true },
    component: () => import("./views/master/materi/add-materi.vue"),
  },
  {
    path: "/master/materi/edit-materi/:id",
    name: "edit-materi",
    meta: { authRequired: true },
    component: () => import("./views/master/materi/edit-materi.vue"),
  },
  {
    path: "/master/materi/setting-materi/:id",
    name: "setting-materi",
    meta: { authRequired: true },
    component: () => import("./views/master/materi/setting-materi.vue"),
  },
  // {
  //   path: "/master/simulasi-config/:id/edit",
  //   name: "edit-simulasi-config",
  //   meta: { authRequired: true },
  //   component: () =>
  //     import("./views/master/simulasi-config/edit-simulasi-config.vue"),
  // },
  {
    path: "/master/kuisioner",
    name: "all-kuisioner",
    meta: { authRequired: true },
    component: () => import("./views/master/kuisioner/all-kuisioner.vue"),
  },
  {
    path: "/master/kuisioner/:id",
    name: "detail-kuisioner",
    meta: { authRequired: true },
    component: () => import("./views/master/kuisioner/detail-kuisioner.vue"),
  },
  {
    path: "/master/kuisioner-combo",
    name: "all-kuisioner-combo",
    meta: { authRequired: true },
    component: () => import("./views/master/kuisioner_combo/all-kuisioner-combo.vue"),
  },
  {
    path: "/master/kuisioner-combo/:id",
    name: "detail-kuisioner-combo",
    meta: { authRequired: true },
    component: () => import("./views/master/kuisioner_combo/detail-kuisioner-combo.vue"),
  },

  {
    path: "/detail-jadwal/:id",
    name: "detail-jadwal",
    meta: { authRequired: true },
    component: () => import("./views/detail-jadwal/all-detail-jadwal.vue"),
  },
  {
    path: "/detail-jadwal/:id_jadwal/:id_jadwal_detail/:tipe/:tipe_pengerjaan",
    name: "detail-jadwal-jawaban-asesi",
    meta: { authRequired: true },
    component: () => import("./views/detail-jadwal/jawaban-peserta/jawaban.vue"),
  },
  {
    path: "/kuesioner/:id_jadwal/:id_simulasi/:pin",
    name: "kuesioner",
    meta: { authRequired: true },
    component: () => import("./views/detail-jadwal/jawaban-peserta/kuesioner.vue"),
  },
  {
    path: "/kuesioner-combo/:id_jadwal/:id_simulasi/:pin",
    name: "kuesioner-combo",
    meta: { authRequired: true },
    component: () => import("./views/detail-jadwal/jawaban-peserta/kuesioner-combo.vue"),
  },
  {
    path: "/master/simulasi-file",
    name: "all-simulasi-file",
    meta: { authRequired: true },
    component: () => import("./views/master/simulasi-files/all-simulasi-files.vue"),
  },
  //monitoring ptl
  {
    path: "/monitoring-ptl/:id/:peran",
    name: "monitoring-ptl",
    meta: { authRequired: true },
    component: () => import("./views/monitoring-ptl/monitoring.vue"),
  },
  {
    path: "/monitoring-ptl/detail/:id/:id_simulasi/:nip_asesi/:intake_type/:id_jadwal_detail/:posisi",
    name: "monitoring-ptl-detail",
    meta: { authRequired: true },
    component: () => import("./views/monitoring-ptl/monitoring-detail.vue"),
  },
  //monitor admin sama dengan monitor ptl
  {
    path: "/monitoring-admin/:id",
    name: "monitoring-admin",
    meta: { authRequired: true },
    component: () => import("./views/monitoring-ptl/monitoring.vue"),
  },
  {
    path: "/monitoring-admin/detail/:id/:id_simulasi/:nip_asesi/:intake_type/:id_jadwal_detail/:posisi",
    name: "monitoring-admin-detail",
    meta: { authRequired: true },
    component: () => import("./views/monitoring-ptl/monitoring-detail.vue"),
  },
  //monitoring asesor
  {
    path: "/monitoring-asesor/:id",
    name: "monitoring-asesor",
    meta: { authRequired: true },
    component: () => import("./views/monitoring-asesor/monitoring.vue"),
  },
  {
    path: "/monitoring-asesor/detail/:id/:id_simulasi/:nip_asesi",
    name: "monitoring-asesor-detail",
    meta: { authRequired: true },
    component: () => import("./views/monitoring-ptl/monitoring-detail.vue"),
  },
  {
    path: "/rekap-kuisioner",
    name: "all-rekap-kuisioner",
    meta: { authRequired: true },
    component: () => import("./views/rekap-kuisioner/all-rekap.vue"),
  },
];
