<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
// import Swal from "sweetalert2";
export default {
  props: ["simulasi_id_store"],
  components: {
    Multiselect,
    QuillEditor,
  },
  data() {
    return {
      // variable Page
      option_tipe_file: ["PDF", "JPEG", "Rich Text", "PPT", "Excel", "Video"],
      option_tipe_file_materi: [
        "MEMO",
        "MAIL",
        "SMS",
        "WA",
        "VIDEO",
        "NEWS",
        "AUDIO",
      ],
      option_simulasi_config: [],
      form_data: {
        judul: "",
        deskripsi: "",
        link_yt: "",
        isPetunjuk: false,
        simulasiconfig: "",
        target_user: "",
        file: "",
        tipe_file: "",
        tipe_file_materi: "",
        rich_text: "",
        tipe: "",
      },
    };
  },
  mounted() {
    this.getSimulasiConfig();
  },
  methods: {
    StoreData() {
      let self = this;

      if (
        !self.form_data.judul ||
        !self.form_data.simulasiconfig ||
        !self.form_data.target_user ||
        !self.form_data.tipe
      ) {
        // Handle validation error, for example, show an error message
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Masukkan data yang dibutuhkan!",
        });
        return; // Exit the function if validation fails
      }
      let formData = new FormData();
      formData.append("judul", self.form_data.judul);
      formData.append("deskripsi", self.form_data.deskripsi);
      formData.append("link_yt", self.form_data.link_yt);
      formData.append("isPetunjuk", self.form_data.isPetunjuk);
      formData.append("simulasiconfig", self.form_data.simulasiconfig);
      formData.append("target_user", self.form_data.target_user);
      formData.append("file", self.form_data.file); // Append the image file
      formData.append("tipe_file", self.form_data.tipe_file); // Append the image file
      formData.append("tipe_file_materi", self.form_data.tipe_file_materi); // Append the image file
      formData.append("rich_text", self.form_data.rich_text); // Append the image file
      formData.append("tipe", self.form_data.tipe); // Append the image file

      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi-files",
        data: formData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          "content-type": "multipart/form-data",
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master simulasi files segera",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              window.location.reload();
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
    onFileChange(e) {
      this.form_data.file = e.target.files[0];
      console.log(this.form_data.file);
    },
    getSimulasiConfig(keyword) {
      let self = this;

      var config_get_jenis_asesmen = {
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/simulasi-config",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          keyword,
        },
      };

      axios(config_get_jenis_asesmen)
        .then(function (response) {
          self.option_simulasi_config = response.data.data.referensi;
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
        });
    },
    searchSimulasiConfig(data) {
      this.getSimulasiConfig(data);
    },
    check(data) {
      console.log(data);
    },
  },
};
</script>
<template>
  <b-form class="p-2" @submit.prevent="StoreData">
    <div class="row">
      <div class="col-sm-12">
        <b-form-group
          class="mb-3"
          label="Simulasi Config"
          label-for="form row-nama-role-input"
        >
          <multiselect
            v-model="form_data.simulasiconfig"
            :options="option_simulasi_config"
            value-prop="simulasiconfig_id"
            :close-on-select="true"
            track-by="nama"
            :searchable="true"
            label="nama"
            placeholder="Pilih Simulasi"
            @search-change="searchSimulasiConfig($event)"
          ></multiselect>
        </b-form-group>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <b-form-group
          class="mb-3"
          label="Judul"
          label-for="formrow-nama-role-input"
        >
          <b-form-input
            id="formrow-nama-role-input"
            placeholder="Judul"
            type="text"
            v-model="form_data.judul"
            required
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <label>target User</label>
        <multiselect
          v-model="form_data.target_user"
          :options="['Asesi', 'Asesor', 'Roleplayer']"
          :close-on-select="true"
          placeholder="Pilih Target User"
          reqired
        ></multiselect>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-12">
        <label>Tipe</label>
        <multiselect
          v-model="form_data.tipe"
          :options="['Materi', 'Petunjuk']"
          :close-on-select="true"
          placeholder="Pilih Tipe"
        ></multiselect>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="mt-3">
          <label for="formFile" class="form-label">Deskripsi</label>
          <textarea
            v-model="form_data.deskripsi"
            class="form-control"
            name="textarea"
            rows="5"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <label>Tipe File</label>
        <multiselect
          v-model="form_data.tipe_file"
          :options="option_tipe_file"
          :close-on-select="true"
          placeholder="Pilih Tipe File"
        ></multiselect>
      </div>
    </div>

    <div
      class="row mt-2"
      v-if="form_data.tipe_file == 'JPEG' || form_data.tipe_file == 'PDF'"
    >
      <div class="col-sm-12">
        <div class="">
          <label for="formFile" class="form-label">File</label>
          <input
            class="form-control"
            type="file"
            id="formFile"
            ref="inputFile"
            v-on:change="onFileChange($event)"
          />
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-12">
        <label>Tipe File Materi</label>
        <multiselect
          v-model="form_data.tipe_file_materi"
          :options="option_tipe_file_materi"
          :close-on-select="true"
          placeholder="Pilih Tipe File Materi"
        ></multiselect>
      </div>
    </div>
    <div class="row mt-2" v-if="form_data.tipe_file == 'Rich Text'">
      <div class="col-md-12">
        <b-form-group
          class="mb-3"
          label="Rich Text"
          label-for="formrow-nama-role-input"
        >
          <QuillEditor
            v-model:content="form_data.rich_text"
            style="height: 200px"
            content-type="html"
          ></QuillEditor>
        </b-form-group>
      </div>
    </div>
    <div
      class="row mt-2"
      v-if="
        form_data.tipe_file == 'Video' ||
        form_data.tipe_file == 'Excel' ||
        form_data.tipe_file == 'PPT'
      "
    >
      <div class="col-md-12">
        <b-form-group
          class="mb-3"
          label="Link"
          label-for="formrow-nama-role-input"
        >
          <b-form-input
            id="formrow-nama-role-input"
            placeholder="Link"
            type="text"
            v-model="form_data.link_yt"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div
      class="mt-4"
      v-if="
        form_data.tipe_file == 'Video' ||
        form_data.tipe_file == 'Excel' ||
        form_data.tipe_file == 'PPT'
      "
    >
      <h6 class="text-danger">
        Berikut contoh untuk format link yang akan diinputkan pada field berikut
        :
      </h6>
      <ul style="text-red" class="text-danger">
        <li>
          <b>Google Drive :</b>
          https://drive.google.com/file/dskkwoiowio12o1i31oi31oi131l/preview
        </li>
        <li>
          <b>Youtube</b> : https://www.youtube.com/embed/soi381808310391211"
          title="YouTube video player" frameborder="0" allow="accelerometer;
          autoplay; clipboard-write; encrypted-media; gyroscope;
          picture-in-picture; web-share"
        </li>
        <li>
          <b>Gsheets</b> :
          https://docs.google.com/spreadsheets/d/e/19289183YSYUHSjhxns/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false
        </li>
        <li>
          <b>GSlides</b> :
          https://docs.google.com/presentation/d/ieroioskdsiodw_7392X423/embed?start=true&loop=false&delayms=3000
        </li>
      </ul>
    </div>
    <hr />
    <div class="row mt-2">
      <div class="col-12 text-end">
        <button type="submit" class="btn btn-primary btn-md">
          <i class="fa fa-edit"></i> Simpan
        </button>
      </div>
    </div>
  </b-form>
</template>

<style scoped>
ul {
  list-style-position: inside;
  padding-left: 0;
}
</style>
