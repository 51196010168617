<script>
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import ModalAddMateri from "./add-materi.vue";
// import ModalDuplikatMateri from "./duplikat-materi.vue";
import ModalEditMateri from "./edit-materi.vue";

export default {
  props: ["id_simulasi", "id_simulasi_config", "jumlah_soal", "jumlah_petunjuk", "is_locked_materi"],
  components: {
    Datatable,
    Pagination,
    ModalAddMateri,
    ModalEditMateri,
    // ModalDuplikatMateri,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "materi_file_id" },
      { width: "250px", label: "File", name: "file" },
      { width: "auto", label: "Tipe", name: "tipe" },
      { width: "auto", label: "Nama File", name: "judul" },
      { width: "auto", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Materi",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Simulasi Config",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      url_backend_pdf_js: process.env.VUE_APP_BACKEND_URL + "/pdf.js/web/viewer.html?file=",
      url_backend_file: process.env.VUE_APP_BACKEND_URL,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      form_add_materi: false,
      form_duplikat_materi: false,
      filter_simulasi_file: [],
      form_edit_materi: false,
      id_materi_selected: "",
      // parsing data ke table data
      tableData: {
        per_page: 10,
        search: "",
        sort_field: 0,
        sort_order: "desc",
        is_petunjuk: false,
        simulasi_id: this.$props.id_simulasi,
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    let self = this;
    console.log(this.id_simulasi);
    self.getDataTable();
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/materi-files") {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          this.filter_simulasi_file = [];
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list;
            self.table_data.forEach((element) => {
              this.filter_simulasi_file.push(element.simulasi_file.simulasi_file_id);
            });
            self.configPagination(response_data_fix.pagination);
            self.currentTablePage = response_data_fix.pagination.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    changeStatusData(status, id) {
      let self = this;

      let text_alert = "";
      if (status == "ENABLE") {
        text_alert = "Apakah anda yakin ingin mengaktifkan data tersebut?";
      } else {
        text_alert = "Apakah anda yakin ingin menonaktifkan data tersebut?";
      }

      Swal.fire({
        title: "Perhatian",
        text: text_alert,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "put",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi-config/" + id + "/status",
            data: {},
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil diperbarui.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  window.location.reload();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/materi-files/" + id,
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  this.getDataTable();
                  self.$emit("item-added");
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    showModalDelete() {
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Materi File ini tidak bisa dihapus, Karena sudah dipilih di setting MK",
        timer: 2000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: false,
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          Swal.close();
        }
      });
    },
    refreshTable() {
      let self = this;
      this.form_add_materi = false;
      this.form_duplikat_materi = false;
      self.form_edit_materi = false;
      this.getDataTable();
      self.$emit("item-added");
    },
    editData(id) {
      let self = this;
      self.form_edit_materi = true;
      self.id_materi_selected = id;
    },
  },
};
</script>

<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <div class="card-title">Materi</div>
      </div>
      <div class="col-md-6">
        <div class="text-end" v-if="!is_locked_materi">
          <button
            class="btn btn-sm btn-success"
            @click="form_add_materi = !form_add_materi"
            v-if="jumlah_soal == 'Multiple' || (jumlah_soal == 'Single' && table_data.length < 1 && !is_locked_materi)"
          >
            <i class="fa fa-plus"></i> Tambah
          </button>
        </div>
      </div>
      <div class="col-md-12">
        <hr />
      </div>
      <div class="col-md-12 mb-2">
        <div class="row">
          <div class="col-md-4">
            <table>
              <tr>
                <td>Show</td>
                <td>
                  <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.per_page" @change="getDataTable()">
                    <option value="10" selected>10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                  </select>
                </td>
                <td>Entries</td>
              </tr>
            </table>
          </div>
          <div class="col-md-5">&nbsp;</div>
          <div class="col-md-3">
            <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..." v-model="tableData.search" @input="getDataTable()" />
          </div>
        </div>
      </div>
      <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
        <tbody>
          <tr v-if="loadingTable">
            <td colspan="6"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
          </tr>
          <tr v-else-if="table_data == ''">
            <td class="text-center" colspan="6">Data Tidak Tersedia</td>
          </tr>
          <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
            <td class="text-center">
              <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
              <div v-else>{{ currentTablePage - 1 != 0 ? currentTablePage - 1 : "" }}{{ key_data + 1 }}.</div>
            </td>
            <td class="text-center">
              <div v-if="row_data.simulasi_file.tipe == 'Video' || row_data.simulasi_file.file == 'PPT' || row_data.simulasi_file.file == 'Excel'">
                <a :href="row_data.simulasi_file.link_yt" target="_blank" class="btn btn-primary btn-sm"> <i class="fa fa-eye me-2"></i>Lihat File Sekarang </a>
              </div>
              <div v-else-if="row_data.simulasi_file.tipe == 'PDF'">
                <a :href="url_backend_pdf_js + url_backend_file + row_data.simulasi_file.file" target="_blank" class="btn btn-primary btn-sm"> <i class="fa fa-eye me-2"></i>Lihat File Sekarang </a>
              </div>
              <div v-else-if="row_data.simulasi_file.tipe == 'Rich Text'">Tipe File Rich Text</div>
              <div v-else>
                <a :href="url_backend_file + row_data.simulasi_file.file" target="_blank" class="btn btn-primary btn-sm"> <i class="fa fa-eye me-2"></i>Lihat File Sekarang </a>
              </div>
            </td>
            <td>
              {{ row_data.simulasi_file.tipe }}
            </td>
            <td>
              {{ row_data.simulasi_file.judul }}
            </td>
            <td class="text-center" v-if="!is_locked_materi">
              <div class="btn-group">
                <button type="button" v-if="!row_data.has_t_materi" class="btn btn-danger btn-sm" v-on:click="deleteData(row_data.materi_file_id)"><i class="fa fa-trash"></i> Hapus</button>
                <button type="button" class="btn btn-info btn-sm" v-on:click="editData(row_data.simulasi_file_id)" v-if="!is_locked_materi"><i class="fa fa-eye"></i> Edit</button>
              </div>
            </td>
          </tr>
        </tbody>
      </datatable>
    </div>
    <pagination :pagination="pagination" @to-page="toPage"></pagination>
  </div>
  <b-modal v-model="form_add_materi" id="modal-lg" title="Tambah Soal" size="lg" title-class="font-18" hide-footer>
    <ModalAddMateri
      v-if="form_add_materi"
      :simulasi_id_store="id_simulasi"
      :simulasiconfig_id="id_simulasi_config"
      :filter_simulasi_file="filter_simulasi_file"
      :jumlah_soal="jumlah_soal"
      @item-added="refreshTable"
    />
  </b-modal>
  <!-- <b-modal v-model="form_duplikat_materi" id="modal-lg" title="Duplikat Soal" size="lg" title-class="font-18" hide-footer>
    <ModalDuplikatMateri :simulasi_id_store="id_simulasi" :simulasiconfig_id="id_simulasi_config" :filter_simulasi_file="filter_simulasi_file" :jumlah_soal="jumlah_soal" @item-added="refreshTable" />
  </b-modal> -->
  <b-modal v-model="form_edit_materi" id="modal-lg" title="Edit Petunjuk" size="lg" title-class="font-18" hide-footer>
    <ModalEditMateri v-if="form_edit_materi" :simulasi_id="id_materi_selected" @item-added="refreshTable" />
  </b-modal>
</template>
