<script>
export default {
  data() {
    return {
      index: "",
    };
  },
  mounted() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage() {
      this.index = 1;
    },
  },
};
</script>
<template>
  <div>
    {{ index }}
  </div>
</template>
